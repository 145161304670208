.header {
  display: flex;
  justify-content: space-between;
  &__logo {
    display: flex;
    justify-content: center;
    img {
      width: 200px;
    }
  }
  &__button-logout {
    border: 1px solid #002060;
    border-radius: 16px;
    color: #002060;
  }
}
