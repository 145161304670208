.notification {
  width: 50%;
  padding-right: 5px;

  .ant-card-body {}

  &__card {
    &__extra {
      display: flex;
      justify-content: space-between;
    }
  }

  &__search {
    margin-right: 20px;
  }

  &__table {
    &__date {
      opacity: 0.5;

      &__unread {
        font-weight: 'bold';
      }
    }

    &__author {
      color: #002060;
      text-align: right;
    }

    &__message {
      cursor: pointer;
    }

    &__unread {
      font-weight: bold;
    }
  }

  &__mess {
    height: 51px;
    display: flex;

    &__type {
      margin-right: 5px;
      height: 40px;

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #002060;
        border: 0.25px solid #002060;
        border-radius: 9px;
        color: #ffffff;
        height: 40px;
      }

      .ant-select {
        width: 100%;
      }

      .ant-select-arrow {
        color: #ffffff;
      }
    }

    &__text {
      flex: 1;
      height: 40px;

      input {
        width: 100%;
      }
    }
  }

  &__send-mess {
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
  }

  &__line {
    color: #f0f0f0;
  }

  &__footer {
    width: 95%;
    position: absolute;
    bottom: 0;
  }

  .ant-table-content {
    // height: 69vh;
    // min-height: 590px;
    overflow: auto !important;
  }

  .ant-input-affix-wrapper {
    border: 0.771694px solid #002060;
    border-radius: 7.71694px;
  }

  .ant-table-body {
    overflow-y: auto !important;
  }

  .table-row-issue {
    background-color: #ececec;

    &:hover {
      background-color: #ececec;
    }
  }
}

.service-request {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__icon {
    display: block;
  }

  &__title {
    color: #1a1a1a;
    font-size: 24px;
    font-weight: 700;
  }

  &__button {
    color: inherit;

    &:hover {
      border: 1px solid #5ebcee;
      color: #5ebcee;
    }

    &:focus,
    &:active {
      border: 1px solid #abafb6;
      box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
      color: black;
    }
  }

  &__description {
    text-align: center;
    width: 350px;
  }

  &__content {
    width: 100%;
    margin-top: 30px;

    &__input {
      margin-right: 5px;
    }

    display: flex;
    justify-content: space-between;
  }
}

.modal-notification {
  &__box-content {
    max-height: 452px;
    width: 100%;
    overflow: auto;
  }

  .ant-modal-content {
    box-shadow: 0px 5px 20px rgba(0, 32, 96, 0.1);
    border-radius: 20px;
  }

  .ant-modal-header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .ant-modal-body {
    max-height: 500px;
  }
}

.modal-message {
  &__title {
    &__id {
      padding-right: 15px;
    }

    &__date {
      font-size: 14px;
      opacity: 0.5;
      font-weight: 400;
    }
  }

  .ant-modal-body {
    max-height: 500px;
  }
}