.user-deatil {
  width: 550px !important;

  .ant-modal-header {
    border-radius: 8px;
  }

  .react-tel-input .form-control {
    width: 100%;
    height: 40px;
    color: #002060;
  }

  .ant-input {
    font-weight: 400;
    color: #002060;
  }

  .ant-input[disabled] {
    color: #002060;
    opacity: .5;
  }

  .ant-tag {
    font-size: 14px;
    padding: 5px 10px;
    color: #002060;
  }

  .role-content {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 10px;
    background: #D9D9D9;
  }
}