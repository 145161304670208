.setting-permission {
  &__parrent-link {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: auto;
    grid-template-rows: repeat(10, auto);
    gap: 2px;
  }
  &__link-module {
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  &__dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: black;
    display: inline-block;
    margin-right: 5px;
    line-height: 20px;
  }
  .card-layout-permission {
    height: 75vh;
  }
}
