.execute-manual-test-case {
  overflow: hidden;
  width: auto !important;
  top: 10px !important;

  .ant-modal-close-x {
    display: none;
  }

  .add-new-modal__header {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #002060;
  }

  .ant-card-bordered {
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid transparent;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .test-case__step_upload_title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
  }

  .test-case__step_upload {
    display: flex;
    justify-content: center;

    .test-case__step_upload-contain {
      width: 70%;
      flex-shrink: 0;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    }
  }

  .step_upload__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .step_upload__item-header {
      margin-top: 20px;
      margin-bottom: 8px;

      .step_upload__item-title {
        color: #0f0f0f;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      .step_upload__item-link {
        margin-left: 5px;
        color: #002060;
        font-weight: 700;
        text-decoration-line: underline;
      }
    }

    .tep_upload__item-text {
      color: #676767;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .step_upload__item:first-child {
    margin-top: 26px;
  }

  .step_upload__item:last-child {
    margin-bottom: 16px;
  }

  .modal_container-action {
    margin-top: 10px;

    .test-case__step__title {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize;
      width: 100%;
    }

    .step_action-textArea {
      color: #6bb2d6;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 18px */
    }

    .step_action-layer {
      margin-top: 10px;
      border-radius: 9px;
      border: 0.25px solid #d9d9d9;
      padding: 10px;

      .step_action-text {
        color: #6bb2d6;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        height: 100px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .step_action-layer-activity {
      width: 100%;
    }

    .step_action-button__actual {
      width: 100%;
      margin-top: 22px;
      display: flex;
      justify-content: space-between;

      .button {
        padding: 0 40px;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        background: #002060;
        border-radius: 4px;
      }

      .button_prev {
        background: #bebebe;
      }
    }

    .step_action-button {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 32px;

      .button {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        background: #002060;
        border-radius: 4px;
      }
    }

    .execution-detail__textArea {
      margin-top: 10px;
      width: 100%;
      color: #5daad6;
      font-size: 14px;
      font-weight: normal !important;
      resize: none;
    }
  }

  .attachment_preview_img {
    margin-bottom: 20px;
  }

  .ant-upload-list {
    display: none !important;
  }

  .ant-upload-span {
    display: none;
  }

  .button-upload {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #002060;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0 2px 4px rgb(0 0 0/7%);
    height: 42px;
    width: 100%;
    padding: 0 46px !important;

    &:hover {
      background-color: #002060;
    }
  }

  .layout-dashboard {
    overflow: hidden;
  }

  .testcase__multiple_paragraph {
    margin-top: 10px;
    border-radius: 9px;
    border: 0.25px solid #d9d9d9;
    padding: 10px;
    height: 136px;
    overflow: hidden;
    line-height: 1.4em;
    background-color: #f5f5f5;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }

  .ant-image-img {
    object-fit: contain;
  }
}