.permission-detail {
  &__back {
    color: #002060;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
    display: inline-block;
  }
  &__link-module {
    height: 260px;
    overflow: auto;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    background-color: #f5f5f5;
    padding: 10px;
  }
  &__button {
    float: right;
  }
  .ant-card-body {
    padding-bottom: 0px;
  }
}

.card-layout.permission-detail {
  width: 99.5%;
}
