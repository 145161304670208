.newpassword {
  .container {
    height: 100vh;
    font-family: Raleway;
  }

  .card {
    border-radius: 30px;
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
  }

  .label_form {
    font-family: 'Poppins';
    font-size: 16px;
    color: #868b90;
  }

  .input_password {
    background-color: #f9f9f9;
    border-radius: 12px;
    height: 40px;
  }

  .ant-input {
    height: auto;
  }

  .ant-input:placeholder-shown {
    background-color: #f9f9f9;
    font-size: 14px;
    font-weight: 400;
  }

  .ant-input-affix-wrapper>input.ant-input {
    background-color: #f9f9f9;
  }

  .ant-input-affix-wrapper {
    background-color: #f9f9f9 !important;
  }

  @media (min-width: 767px) {
    .img_header {
      width: 130px;
      height: 38px;
    }

    .img_footer {
      width: 80px;
      height: auto;
      position: absolute;
    }
  }

  @media (max-width: 767px) {
    .img_header {
      width: 60px;
      height: 20px;
    }

    .img_footer {
      width: 60px;
      height: auto;
      position: absolute;
    }
  }

  .col_imgHeader {
    justify-content: flex-end;
    display: flex;
  }

  .text_title {
    display: flex;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 0;
    align-self: flex-end;
    font-size: 38px;
  }

  .a {
    display: flex;
    justify-content: space-between;
  }

  .openmodalPass {
    color: '#4B4B4B';
    text-decoration: underline;
  }

  .confirm_button {
    margin-top: 20px;
    background: #002060 !important;
    width: 100%;
    height: 51px;
    border: none;
    border-radius: 12px;

    &:hover {
      background: #002060 !important;
    }

    .text_confirm_button {
      font-size: 18px;
      color: #fff !important;
    }
  }

  .card_footer {
    display: flex;
    justify-content: flex-start;
  }

  .text_footer {
    margin-top: 70px;
    color: #3c5896;
    font-size: 16px;
  }

  .texttitle_forgotPassword {
    display: flex;
    justify-content: center;
    font-weight: bold;
    // font-family: 'Poppins';
    align-self: flex-end;
  }

  .text_header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    margin-bottom: 35px;
  }

}