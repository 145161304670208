.decision-table__detail {
  &__link {
    color: #005080;
    cursor: pointer;
  }

  &__title {
    &--italic {
      font-weight: 300;
      font-style: italic;
      font-size: 14px;
      width: 600px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }

  .ant-modal-body {
    height: 532px;
  }
}

.pop-up-cconfirm-import {
  .ant-modal-close-x {
    display: none;
  }
}

.pop-up-confirm-delete-row {
  width: 370px !important;

  .elm-dot {
    height: 5px;
    width: 5px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
  }
}

.create-new-group {
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }
}