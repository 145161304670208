.sign-up {
  overflow: auto;

  .container {
    height: 100vh;
  }

  .card {
    border-radius: 30px;
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
  }

  .input_username {
    background: #f9f9f9;
    height: 40px;
    border-radius: 12px;
  }

  .input_username::placeholder {
    font-size: 16px;
    font-weight: 400;
  }

  .input_password {
    background-color: #f9f9f9;
    border-radius: 12px;
    height: 40px;
  }

  .input_password::placeholder {
    font-size: 16px;
    font-weight: 400;
  }

  .ant-input:placeholder-shown {}

  @media (min-width: 767px) {
    .img_header {
      width: 130px;
      height: 38px;
    }

    .img_footer {
      width: 80px;
      height: auto;
      position: absolute;
    }
  }

  @media (max-width: 767px) {
    .img_header {
      width: 60px;
      height: 20px;
    }

    .img_footer {
      width: 60px;
      height: auto;
      position: absolute;
    }
  }

  .label_form {
    font-size: 16px;
    color: #868b90;
  }

  .col_imgHeader {
    justify-content: flex-end;
    display: flex;
  }

  .text_title {
    display: flex;
    font-size: 38px;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 0;
    align-self: flex-end;
  }

  .a {
    display: flex;
    justify-content: space-between;
  }

  .openmodalPass {
    color: '#4B4B4B';
    text-decoration: underline;
  }

  .login_button {
    background: #002060 !important;
    width: 100%;
    height: 51px;
    border: none;
    border-radius: 12px;

    &:hover {
      background: #002060 !important;
    }

    .text_login_button {
      color: #fff !important;
      font-size: 18px;
    }
  }

  .card_footer {
    display: flex;
    justify-content: flex-start;
  }

  .text_footer {
    margin-top: 70px;
    color: #3c5896;
    font-size: 16px;
  }

  .texttitle_forgotPassword {
    display: flex;
    justify-content: center;
    font-weight: bold;
    align-self: flex-end;
  }

  .text_header {
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 35px;
  }

  .react-tel-input {
    border-radius: 8px;
  }

  .react-tel-input .form-control {
    width: 100%;
    height: 40px;
    color: #002060;
    border-radius: 12px;
  }

  .react-tel-input .flag-dropdown {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .ant-input {
    // height: auto;
    font-weight: 500;
    color: #002060;
  }

  .ant-input-password {
    height: 40px;
    border-radius: 12px;

    .ant-input {
      height: auto;
    }
  }

  .ant-input[disabled] {
    color: #002060;
    opacity: .5;
  }

  .contact-number::placeholder {
    color: #888;
  }

  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .react-tel-input .flag-dropdown.open .selected-flag {
    border-radius: 12px;
  }
}

.ForbiddenPage {
  text-align: center;
  margin-top: 150px;

  h1 {
    font-size: 100px;
    margin-bottom: 0;
  }

  h3 {
    font-size: 30px;
  }
}