.sidebar {
  .logo {
    display: flex;
    justify-content: center;

    img {
      width: 150px;
    }
  }

  .menu-icon {
    margin-right: 5px;
  }

  &__select-project {
    width: 100%;

    .ant-select-selector {
      border-radius: 15px !important;
      background-color: #002060 !important;
      color: #ffffff;
      font-size: 14px;
    }
  }

  .menu-item-header {
    padding: 10px 16px !important;
    color: #ffffff;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    display: block;
  }

  position: relative;

  .show-block-nav {
    .overlay_slidebar {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 9 !important;
      animation: fadeIn linear 0.3s;
      opacity: 0;
      cursor: pointer;
    }
  }
}