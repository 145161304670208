.role-detail {
  &__back {
    color: #002060;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
    display: inline-block;
  }
  &__link-module {
    height: 260px;
    overflow: auto;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    background-color: #f5f5f5;
    padding: 10px;
  }
  &__button {
    float: right;
  }

  &__permission-label {
    padding-bottom: 8px;
  }
  &__module-permission {
    height: 358px;
    overflow: auto;
    margin-bottom: 24px;
    .ant-form-item {
      margin-bottom: 0px;
    }
    .ant-collapse {
      margin-bottom: 14px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
      margin-bottom: 3px;
      margin-left: 3px;
    }
  }
  .ant-card-body {
    padding-bottom: 0px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 3px 0px 3px;
  }
}
